@import 'mixins/global';

#AppContent .content_block{
	.block_picto{
		width: 110px;
		height: 110px;
		position: absolute;
		@include transform(translate(-50%, -50%));
		left: 50%;
		.picto_img{
			position: absolute;
			left: 50%;
			top: 60%;
			@include transform(translate(-50%, -50%));
			opacity: 0;
			@include transition((all), 0.6s, ease-in-out, 0.9s);
		}
		.picto_contour{
			position: absolute;
			left: 50%;
			top: 50%;
			width: 100%;
			height: 100%;
			@include transform(translate(-50%, -50%));
			&.contour_1{
				polyline{
					stroke-dasharray:391px;
					stroke-dashoffset:391px;
					@include transition((all), 0.3s, ease-in-out, 0s);
				}
			}
			&.contour_2{
				polyline{
					stroke-dasharray:356px;
					stroke-dashoffset:356px;
					@include transition((all), 0.3s, ease-in-out, 0.3s);
				}
			}
			&.contour_3{
				polyline{
					stroke-dasharray:251px;
					stroke-dashoffset:251px;
					@include transition((all), 0.3s, ease-in-out, 0.6s);
				}
			}
		}
	}

	&.animated{
		.block_picto{
			.picto_contour{
				&.contour_1{ polyline{ stroke-dashoffset:782px; } }
				&.contour_2{ polyline{ stroke-dashoffset:712px; } }
				&.contour_3{ polyline{ stroke-dashoffset:502px; } }
			}
			.picto_img{
				opacity: 1;
				top: 50%;
			}
		}
	}

	.picto_badge{
		width: 180px;
		height: 218px;
		position: absolute;
		@include transform(translate(-50%, -50%));
		left: 50%;
		top: 190px;
		.picto_img{
			position: absolute;
			left: 50%;
			top: 60%;
			@include transform(translate(-50%, -50%));
			opacity: 0;
			@include transition((all), 0.6s, ease-in-out, 0.9s);
		}
		.picto_contour{
			position: absolute;
			left: 50%;
			top: 50%;
			width: 100%;
			height: 100%;
			@include transform(translate(-50%, -50%));
			&.contour_1{
				polyline{
					@include transition((all), 0.3s, ease-in-out, 0s);
					&.polyline_1{
						stroke-dasharray:63px;
						stroke-dashoffset:63px;
					}
					&.polyline_2{
						stroke-dasharray:63px;
						stroke-dashoffset:63px;
					}
				}
			}
			&.contour_2{
				polygon{
					@include transition((all), 0.3s, ease-in-out, 0.3s);
					stroke-dasharray: 779px;
					stroke-dashoffset: 779px;
				}
			}
			&.contour_3{
				polygon{
					@include transition((all), 0.3s, ease-in-out, 0.6s);
				}
			}
			&.contour_4{
				polyline{
					@include transition((all), 0.3s, ease-in-out, 0.9s);
					stroke-dasharray: 271px;
					stroke-dashoffset: 271px;
				}
			}
			&.contour_5{
				polyline{
					@include transition((all), 0.3s, ease-in-out, 1.2s);
					stroke-dasharray: 138px;
					stroke-dashoffset: 138px;
				}
			}
		}
	}

	&.animated{
		.picto_badge{
			.picto_contour{
				&.contour_1{ polyline{ stroke-dashoffset:126px; } }
				&.contour_2{ polygon{ stroke-dashoffset:1558px; }}
				&.contour_3{ polygon{ stroke-dashoffset:244px !important; }}
				&.contour_4{ polyline{ stroke-dashoffset:542px; }}
				&.contour_5{ polyline{ stroke-dashoffset:276px; }}
			}
		}
		.picto_img{
			opacity: 1;
			top: 50%;
		}
	}
}

#AppContent .content_block[data-block="intro"]{
	.picto_badge .contour_3{
		width: 50px;
		height: 43px;
		top: 30px;
		polygon{
			&:nth-child(1){
				stroke-dashoffset:96px;
				stroke-dasharray:96px;
			}
			&:nth-child(2){
				stroke-dashoffset:132px;
				stroke-dasharray:132px;
			}
			&:nth-child(3){
				stroke-dashoffset:78px;
				stroke-dasharray:78px;
			} 
		}
		#SVGContourEtoile{
			display: none;
		}
	}
	&.animated{
		.picto_badge .contour_3{
			polygon{
				&:nth-child(1){
					stroke-dashoffset:192px !important;
				}
				&:nth-child(2){
					stroke-dashoffset:264px !important;
				}
				&:nth-child(3){
					stroke-dashoffset:156px !important;
				}   
			}
		}
	}
}

#AppContent .content_block[data-block="badge"]{
	.picto_badge .contour_3{
		polygon{
			stroke-dashoffset:122px;
			stroke-dasharray:122px;  
		}
		#SVGContourHisto{
			display: none;
		}
	}
}