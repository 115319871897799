@import 'mixins/global';

html, body{
	width: 100%;
	height: 100%;
}

body{
	font-family:"staatlichesregular";
	font-size:13px;
	line-height:16px;
	background: $colorCyan;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&.locked{
		#SplashOpacity{
			opacity: 1;
		}
		#Splash{
			@include transform(translate(0,0));
		}
	}

	&.locked_domed{
		position: fixed;
		overflow: hidden;
		#SplashOpacity{
			display: block;
		}
		#Splash{
			display: block;
		}
	}
}


#AppWrapper{
	opacity: 0;
	@include transition((all), 0.3s, ease-in-out);
	&.displayed{
		opacity: 1;
	}
}

#Output{
	position: fixed;
	left: 10px;
	top: 10px;
	font-size: 20px;
}

.cyan{
	color: $colorCyan;
}

.bluedark{
	color: $colorBlueDark;
}

.white{
	color: #fff;
}

.underlined{
	background: #fff;
	color: $colorCyan;
}

.underlined_white{
	background: $colorCyan;
	color: #fff;
	display: inline-block;
	padding: 0px 4px;
}

.underlined_gold{
	background: $colorGold;
	color: #fff;
	display: inline-block;
	padding: 0px 4px;
}

.bolder{
	font-family: "montserrat_blackregular";
}

.odometer{
	.odometer-formatting-mark{
		position: relative;
		&:after{
			content: "";
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			background: $colorCyan;
		}
	}
}

b{
	font-family: "montserrat_blackregular";
}

.odometer-ribbon-inner { -webkit-backface-visibility: visible !important; }
.odometer-value { -webkit-transform: none !important; }

.hugefigure.odometer{
	.odometer-inside{
		height: 85px;
		overflow: hidden;
		.odometer-digit{
			top: -30px;
		}
	}
}

.content_block[data-block="statultime"] .bigfigure{
	.odometer-inside{
		height: 80px;
		overflow: hidden;
		.odometer-digit{
			top: -20px;
		}
	}
}

.content_block[data-block="supporters"] .bigfigure{
	.odometer-inside{
		height: 100px;
		overflow: hidden;
	}
}

.content_block[data-block="statultime"]{
	.odometer .odometer-formatting-mark:after{
		background: $colorBlueDark;
	}
}

.text_hasdata{
	display: inline-block;
}

.text_hasnodata{
	display: none;
}

.content_block.hasnodata{
	.text_hasdata{
		display: none;
	}

	.text_hasnodata{
		display: inline-block;
	}
}

#App{
	text-align:center;
	position: relative;
	overflow: hidden;
	font-family:"staatlichesregular";
	color: #fff;
	z-index: 10;
}

#AppContent{
	max-width: 500px;
	width: 100%;
	padding: 0;
	display: inline-block;
	vertical-align: top;
	text-align: left;
	position: relative;
	z-index: 1000;
	.regular_p{
		font-size: 15px;
		line-height: 18px;
		color: #fff;
		font-family: "montserratregular";
	}
	.bigger_p{
		font-size: 30px;
		line-height: 34px;
		.bigger{
			font-size: 68px;
			line-height: 70px;
		}
	}
	.waybigger_p, .subtitle_p{
		font-size: 36px;
		line-height: 40px;
	}
	.waybigger_p2{
		font-size: 48px;
		line-height: 52px;
		position: relative;
	}
	.hugefigure{
		font-size: 100px;
		line-height: 110px;
		letter-spacing: 2px;
		font-family: "shkoder_1989black";
	}
	.bigfigure{
		font-size: 80px;
		line-height: 90px;
		font-family: "shkoder_1989black";
	}
	.content_block{
		padding: 40px;
		text-align: center;
		position: relative;

		.block_gif{
			width: 100%;
			height: 300px;
			position: relative;
			top: 10px;
			img{
				width: 100%;
			}
		}

		&[data-block="intro"]{
			background: #fff;
			&:before{
				content: "";
				position: absolute;
				left: 50%;
				top: 20px;
				@include transform(translate(-50%, 0));
				background: url("images/logo-om/logoOMbleu-or_filet blanc.png") no-repeat 0 0;
				background-size: 100% 100%;
				width: 66px;
				height: 88px;
			}
			.intro_title, .intro_belowshadow{
				color: $colorBlueDark;
				font-size: 46px;
				line-height: 50px;
				margin-top: 154px;
				position: relative;
				z-index: 10;
				opacity: 0;
				@include transform(translate(0, 40px));
				@include transition((all), 0.6s, ease-in-out, 1.5s);
			}
			.intro_belowshadow{
				position: absolute;
				left: 50%;
				@include transform(translate(-50%, 40px));
				color: #fff;
				text-shadow:1px 1px $colorCyan;
				z-index: 5;
				margin-left: 2px;
				top: 42px;
				.cyan{
					color: #fff;
				}
			}
			&.animated{
				.intro_title{
					opacity: 1;
					@include transform(translate(0, 0px));
				}
				.intro_belowshadow{
					opacity: 1;
					@include transform(translate(-50%, 0px));
				}
			}
			.aboscore_text{
				margin-top: 86px;
				position: relative;
				&:after{
					content: "";
					position: absolute;
					left: 50%;
					@include transform(translate(-50%, 0));
					top: 60px;
					background: url("images/divers/fleche.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 50px;
					height: 30px;
				}
			}
			.regular_p{
				color: $colorCyan;
			}
			.bigger_p{
				color: $colorCyan;
				.underlined{
					background: $colorCyan;
					color: #fff;
					padding: 0 10px;
				}
			}
			.block_gif{
				width: 320px;
				position: relative;
				left: 50%;
				@include transform(translate(-50%, 0));
				img{
					position: absolute;
					left: 50%;
					bottom: 20px;
					@include transform(translate(-50%, 0));
				}
			}
			.picto_badge{
				width: 280px;
				height: 338px;
				top: 290px;
			}
		}

		&[data-block="matchs"]{
			height: 380px;
			&:before{
				content: "";
				position: absolute;
				left: 50%;
				top: 0px;
				@include transform(translate(-50%, 0));
				background: url("images/logo-om/logoOMblanc.png") no-repeat 0 0;
				background-size: 100% 100%;
				width: 60px;
				height: 76px;
			}
			.triangle{
				position: absolute;
				right:0px;
				bottom:100%;
				width: 0;
				height: 0;
				border-style: solid;
				border-color: transparent transparent $colorCyan transparent;
			}
			.triangle_1{
				border-width: 0 0 100px 650px;
			}
			.triangle_2{
				border-width: 0 0 120px 650px;
				opacity: .7;
			}
			.bigger_p, .waybigger_p, .hugefigure{
				position: absolute;
				left: 50%;
				@include transform(translate(-50%, 0));
			}
			.bigger_p{
				margin-left: -42px;
				top: 150px;
			}
			.waybigger_p{
				margin-left: 16px;
				top: 180px;
				text-align: left;
				width: 150px;
			}
			.hugefigure{
				top: 181px;
				width: 220px;
				text-align: right;
				margin-left: -166px;
			}
			.block_picto{
				top: 270px;
				.picto_img{
					background: url("images/pictos/matches.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 115px;
					height: 115px;
				}
			}
		}

		&[data-block="victoires"]{
			height: 300px;
			margin-top: 0px;
			.bigger_p, .waybigger_p, .hugefigure{
				position: absolute;
				right: 50%;
				@include transform(translate(-50%, 0));
			}
			.bigger_p{
				margin-right: -70px;
				top: 40px;
			}
			.waybigger_p{
				margin-right: -216px;
				top: 68px;
				text-align: left;
				width: 150px;
			}
			.hugefigure{
				top: 70px;
				width: 220px;
				text-align: right;
				margin-right: -104px;
			}
			.block_picto{
				left: 50%;
				top: 170px;
				margin-left: 50px;
				.picto_img{
					background: url("images/pictos/victoires.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 115px;
					height: 115px;
				}
			}
		}

		&[data-block="buts"]{
			height: 160px;
			margin-top: 0px;
			.bigger_p, .waybigger_p, .hugefigure{
				position: absolute;
				right: 50%;
				@include transform(translate(-50%, 0));
			}
			.bigger_p{
				margin-right: -150px;
				top: 40px;
			}
			.waybigger_p{
				margin-right: -224px;
				top: 68px;
				text-align: left;
				width: 100px;
			}
			.hugefigure{
				top: 34px;
				width: 220px;
				margin-right: -185px;
				text-align: right;
			}
			.block_picto{
				left: 50%;
				margin-left: -50px;
				top: 80px;
				.picto_img{
					background: url("images/pictos/buts.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 115px;
					height: 115px;
				}
			}
		}

		&[data-block="buts_diapo"]{
			margin-top: 0px;
			.block_diapo{
				width: 100%;
				position: relative;
				.diapo{
					.diapo_inner{
						margin: 5px;
						img{
							width:100%;
						}
					}
				}
				.slick-arrow{
					height: 100%;
					width: 20px;
					z-index: 10;
					&:before{
						display: none;
					}
					&:after{
						content: "";
						position: absolute;
						top: 50%;
						@include transform(translate(0%, -50%));
						width: 40px;
						height: 40px;
					}
					&.slick-next{
						right: 0px;
						&:after{
							right: -40px;
							background: url("images/divers/fleche-caroussel-droite-off.svg") no-repeat 0 0;
							background-size: 100% 100%;
							
						}
						&:hover{
							&:after{
								background: url("images/divers/fleche-caroussel-droite-hover.svg") no-repeat 0 0;
								background-size: 100% 100%;
							}
						}
					}
					&.slick-prev{
						left: 0px;
						&:after{
							left: -40px;
							background: url("images/divers/fleche-caroussel-gauche-off.svg") no-repeat 0 0;
							background-size: 100% 100%;
							
						}
						&:hover{
							&:after{
								background: url("images/divers/fleche-caroussel-gauche-hover.svg") no-repeat 0 0;
								background-size: 100% 100%;
							}
						}
					}
				}
			}
		}

		&[data-block="dribbles"]{
			height: 420px;
			margin-top: 40px;
			.waybigger_p, .hugefigure, .regular_p{
				position: absolute;
				left: 50%;
				@include transform(translate(-50%, 0));
			}
			.waybigger_p{
				margin-left: -78px;
				top: 150px;
			}
			.hugefigure{
				top: 65px;
				width: 220px;
				text-align: right;
				margin-left: -120px;
			}
			.regular_p{
				margin-left: -78px;
				width: 130px;
				top: 200px;
			}
			.block_picto{
				top: 0px;
				.picto_img{
					background: url("images/pictos/dribbles.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 115px;
					height: 115px;
				}
			}
		}

		&[data-block="arrets"]{
			height: 500px;
			margin-top: 130px;
			.waybigger_p, .hugefigure, .regular_p{
				position: absolute;
				left: 50%;
				@include transform(translate(-50%, 0));
			}
			.waybigger_p{
				margin-left: -65px;
				top: 165px;
			}
			.hugefigure{
				top: 72px;
				width: 220px;
				text-align: right;
				margin-left: -110px;
			}
			.regular_p{
				&.p1{
					margin-left: 55px;
					width: 200px;
					top: 250px;
				}
				&.p2{
					margin-left: 55px;
					width: 200px;
					top: 380px;
					display: none;
					&.displayed{
						display: block;
					}
				}
			}
			.block_picto{
				top: 0px;
				.picto_img{
					background: url("images/pictos/arrets.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 115px;
					height: 115px;
				}
			}
		}

		&[data-block="cartons"]{
			height: 640px;
			margin-top: 50px;
			&.smaller{
				height: 300px;
			}
			&.hasnodata{
				display: none;
			}
			.waybigger_p, .hugefigure, .regular_p{
				position: absolute;
				left: 50%;
				@include transform(translate(-50%, 0));
			}
			.waybigger_p{
				margin-left: 100px;
				top: 0px;
				text-align: left;
			}
			.hugefigure{
				top: -2px;
				width: 220px;
				text-align: right;
				margin-left: -82px;
			}
			.regular_p{
				margin-left: 0px;
				width: 100%;
				min-width: 300px;
				padding: 0 40px;
				top: 130px;
			}
			.block_gif{
				position: absolute;
				left: 50%;
				top: 290px;
				@include transform(translate(-50%, 0));
			}
			.block_picto{
				top: 40px;
				margin-left: -130px;
				.picto_img{
					background: url("images/pictos/cartons.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 115px;
					height: 115px;
				}
			}
			.cartons_red, .cartons_var, .block_gif{
				&.notdisplayed{
					display: none;
				}
			}
		}

		&[data-block="badge"]{
			height: 580px;
			padding: 40px 20px;
			.waybigger_p, .hugefigure{
				position: absolute;
				left: 50%;
				@include transform(translate(-50%, 0));
			}
			.waybigger_p{
				margin-left: 120px;
				width: 300px;
				text-align: left;
				top: 468px;
			}
			.hugefigure{
				width: 220px;
				text-align: right;
				margin-left: -140px;
				top: 466px;
			}
			.badge_text{
				margin-top: 310px;
			}
			.picto_badge{
				.picto_img{
					background: url("images/pictos/supporters.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 150px;
					height: 150px;
				}
			}
			.block_usercateg{
				position: absolute;
				top: 320px;
				left: 50%;
				@include transform(translate(-50%, 0));
				width: 100%;
				max-width: 300px;
				&:before{
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					@include transform(translate(-50%, -50%));
					background:url("images/divers/badge-cartouche-titre.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 260px;
					height: 60px;
				}
				.subtitle_p{
					width: 100%;
					display: inline-block;
					margin: 0;
					position: relative;
				}
			}
			.block_picto{
				position: relative;
				display: inline-block;
				vertical-align: top;
				height: 200px;
				width: 100%;
				.picto_img{
					background: url("images/pictos/supporters.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 115px;
					height: 115px;
				}
			}
		}

		&[data-block="supporters"]{
			margin-top: 130px;
			padding-bottom: 160px;
			p{
				margin: 0;
			}
			.block_picto{
				top: -30px;
				.picto_img{
					background: url("images/pictos/supporters.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 115px;
					height: 115px;
				}
			}
			.supporters_nb{
				margin-top: 0px;
			}
			.bigfigure.auxarmes{
				font-family: "staatlichesregular";
			}
			&:before{
				content: "";
				position: absolute;
				bottom: 0px;
				left: 50%;
				@include transform(translate(-50%, 0));
				background: url("images/supporters-600px.png") no-repeat 0 0;
				background-size: 100% 100%;
				width: 320px;
				height: 213px;
			}
			&:after{
				content: "";
				position: absolute;
				bottom: -6px;
				width: 320px;
				left: 50%;
				@include transform(translate(-50%, 0));
				background: $colorGold;
				height: 6px;
			}
		}

		&[data-block="12homme"]{
			margin-top: 40px;
			padding: 40px 30px 0px;
			.bigger_p{
				min-width: 310px;
				position: relative;
				left: 50%;
				@include transform(translate(-50%, 0));
				.bigger{
					margin-top: 10px;
					display: inline-block;
				}
			}
		}

		&[data-block="adversaires"]{
			margin-top: 0px;
			padding: 10px 40px 70px;
			&:before{
				content: "";
				position: absolute;
				bottom: 0px;
				left: 50%;
				@include transform(translate(-50%, 0));
				background: url("images/stade-600px.png") no-repeat 0 0;
				background-size: 100% 100%;
				width: 320px;
				height: 213px;
			}
			&:after{
				content: "";
				position: absolute;
				bottom: -6px;
				width: 320px;
				left: 50%;
				@include transform(translate(-50%, 0));
				background: $colorGold;
				height: 6px;
			}
			.bigger_p{
				position: relative;
				&.completer{
					margin-top: 30px;
				}
				
			}
			.adversaires_logos{
				text-align: left;
				position: relative;
				left: 50%;
				@include transform(translate(-50%, 0));
				width: 340px;
				.logo{
					display: inline-block;
					vertical-align: top;
					width: 50px;
					height: 50px;
					opacity: .3;
					margin: 8px;
					&.active{
						opacity: 1;
					}
					&.notdisplayed{
						display: none;
					}
					&[data-team="amiens"]{
						background: url("images/logos/amiens_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/amiens.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="angers"]{
						background: url("images/logos/angers_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/angers.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="bordeaux"]{
						background: url("images/logos/bordeaux_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/bordeaux.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="caen"]{
						background: url("images/logos/caen_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/caen.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="dijon"]{
						background: url("images/logos/dijon_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/dijon.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="guingamp"]{
						background: url("images/logos/guingamp_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/guingamp.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="lille"]{
						background: url("images/logos/lille_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/lille.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="lyon"]{
						background: url("images/logos/lyon_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/lyon.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="monaco"]{
						background: url("images/logos/monaco_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/monaco.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="montpellier"]{
						background: url("images/logos/montpellier_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/montpellier.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="nantes"]{
						background: url("images/logos/nantes_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/nantes.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="nice"]{
						background: url("images/logos/nice_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/nice.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="nimes"]{
						background: url("images/logos/nimes_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/nimes.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="paris"]{
						background: url("images/logos/paris_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/paris.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="reims"]{
						background: url("images/logos/reims_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/reims.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="rennes"]{
						background: url("images/logos/rennes_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/rennes.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="saint-etienne"]{
						background: url("images/logos/saint-etienne_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/saint-etienne.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="strasbourg"]{
						background: url("images/logos/strasbourg_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/strasbourg.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}
					&[data-team="toulouse"]{
						background: url("images/logos/toulouse_nb.png") no-repeat 0 0;
						background-size: 100% 100%;
						&.active{
							background: url("images/logos/toulouse.png") no-repeat 0 0;
							background-size: 100% 100%;
						}
					}

				}
			}
		}

		&[data-block="tribune"]{
			margin-top: 100px;
			.regular_p{
				margin: 0;
			}
			.tribune_name{
				margin-bottom: 0px;
			}
			.tribune_stadium{
				height: 320px;
				width: 100%;
				text-align: center;
				position: relative;
				.stadium_image{
					width: 363px;
					height: 320px;
					display: inline-block;
					position: relative;
					left: 50%;
					@include transform(translate(-50%, 0));
				}
				&:after{
					content: "";
					position: absolute;
					left: 50%;
					top: 100%;
					margin-top: 0px;
					@include transform(translate(-50%, 0));
					background: url("images/logo-om/logoOMblanc.png") no-repeat 0 0;
					background-size: 100% 100%;
					width: 60px;
					height: 76px;
				}
			}
			.subscribe_bt{
				font-family: "staatlichesregular";
				font-size: 30px;
				line-height: 34px;
				position: relative;
				display: inline-block;
				vertical-align: top;
				margin-top: 100px;
				color: #fff;
				text-decoration: none;
				cursor: pointer;
				.bt_label{
					background: $colorGold;
					position: relative;
					padding: 4px 10px;
				}
				&:before{
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					@include transform(translate(-50%, -50%));
					background: #005173;
					width: 90%;
					height: 110%;
				}
				&:hover{
					.bt_label{
						background: #fff;
						color: $colorGold;
					}
				}
			}
		}

		&[data-block="statultime"]{
			width: 320px;
			display: inline-block;
			vertical-align: top;
			position: relative;	
			left: 50%;
			margin-top: 60px;
			padding: 0;
			@include transform(translate(-50%, 0));
			p{
				margin: 0px;
			}
			.minutes_text{
				position: relative;
				margin-top: 0px;
			}
			&:after{
				content: "";
				position: absolute;
				left: 50%;
				@include transform(translate(-50%, 0));
				bottom: -50px;
				background: url("images/divers/terrain-filaire.svg") no-repeat 0 0;
				background-size: 100% 100%;
				width: 137px;
				height: 90px;
			}
			.statultime_diago{
				position: absolute;
				z-index: 20;
				width: 300px;
				height: 519px;
				pointer-events:none;
				&[data-diago="1"]{
					background: url("images/divers/diagonales-statultime-G.svg") no-repeat 0 0;
					background-size: 100% 100%;
					left: -160px;
					top: -100px;
				}
				&[data-diago="2"]{
					background: url("images/divers/diagonales-statultime-D.svg") no-repeat 0 0;
					background-size: 100% 100%;
					right: -140px;
					top:-10px;
				}
			}
			.block_picto{
				top: 180px;
				.picto_img{
					background: url("images/pictos/matches.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 115px;
					height: 115px;
				}
			}
			.bigfigure{
				margin-top: 120px;
			}
			.statultime_back{
				background: $colorGold;
				width: 100%;
				position: absolute;
				left: 8px;
				top: 8px;
				height: 100%;
			}
			.statultime_bg{
				background:$colorBlueDark;
				position: relative;
				padding: 20px 20px 70px;
				width: 100%;
				&:before{
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 40px 40px 0 0;
					border-color: $colorCyan transparent transparent transparent;
					position: absolute;	
					left: 0px;
					top: 0px;
				}
				&:after{
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 40px 40px;
					border-color: transparent transparent $colorGold transparent;
					position: absolute;	
					right: 0px;
					bottom: 0px;
				}
				.bottomr_triangle{
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 50px 50px;
					border-color: transparent transparent $colorCyan transparent;
					position: absolute;	
					right: -11px;
					bottom: -11px;
					z-index: 10;
				}
			}
		}

		&[data-block="partage"]{
			width: 320px;
			position: relative;	
			left: 50%;
			@include transform(translate(-50%, 0));
			display: inline-block;
			vertical-align: top;
			padding: 0px;
			margin-top: 80px;
			margin-bottom: 80px;
			.partage_encart{
				background: #fff;
				border-radius: 12px;
				padding: 20px;
				color: $colorBlueDark;
				font-size: 15px;
				line-height: 20px;
				font-family: "montserratregular";
				text-align: left;
				width: 100%;
				white-space: pre-wrap;
			}

			.social_block{
				display: block;
				width: 100%;
				text-align: center;
				margin-top: 30px;
				.social_link{
					width: 34px;
					height: 34px;
					vertical-align: top;
					background-size: 100% 100%;
					background-repeat: no-repeat;
					display: inline-block;
					margin: 0 10px;
					cursor: pointer;
					&[data-social="tw"]{
						background-image:url("images/social/rs-twitter.svg");
					}
					&[data-social="fb"]{
						background-image:url("images/social/rs-facebook.svg");
					}
					&[data-social="is"]{
						background-image:url("images/social/rs-instagram.svg");
					}
					&[data-social="lk"]{
						background-image:url("images/social/rs-lien.svg");
					}
				}
			}
			.partage_wdd{
				display: block;
				width: 100%;
				text-align: center;
				margin-top: 30px;
				.wdd_link{
					display: inline-block;
					vertical-align: top;
					background: url("images/wdd-logo-blanc.svg") no-repeat 0 0;
					background-size: 100% 100%;
					width: 180px;
					height: 25px;
				}
			}
		}
	}
}

.answer_playerpic{
	&[data-player="payet"]{
		background: url("images/portrait/payet.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="amavi"]{
		background: url("images/portrait/amavi.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="rami"]{
		background: url("images/portrait/rami.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="sakai"]{
		background: url("images/portrait/sakai.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="thauvin"]{
		background: url("images/portrait/thauvin.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="caleta-car"]{
		background: url("images/portrait/caleta-car.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="germain"]{
		background: url("images/portrait/germain.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="gustavo"]{
		background: url("images/portrait/gustavo.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="strootman"]{
		background: url("images/portrait/strootman.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="pele"]{
		background: url("images/portrait/pele.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="mitroglou"]{
		background: url("images/portrait/mitroglou.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="abdennour"]{
		background: url("images/portrait/abdennour.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="mandanda"]{
		background: url("images/portrait/mandanda.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="radonjic"]{
		background: url("images/portrait/radonjic.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="sarr"]{
		background: url("images/portrait/sarr.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="rolando"]{
		background: url("images/portrait/rolando.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="sanson"]{
		background: url("images/portrait/sanson.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="lopez"]{
		background: url("images/portrait/lopez.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="kamara"]{
		background: url("images/portrait/kamara.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="njie"]{
		background: url("images/portrait/njie.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="ocampos"]{
		background: url("images/portrait/ocampos.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
	&[data-player="luiz-gustavo"]{
		background: url("images/portrait/gustavo.png") no-repeat 0 0;
		background-size: 100% 100%;
	}
}