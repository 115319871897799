@import 'mixins/global';

.parallax_block{
	position: absolute;
	left: 50%;
	@include transform(translate(-50%, 0));
	max-width: 600px;
	width: 100%;
	min-height: 100px;
	opacity: 0;
	&.displayed{
		opacity: 1;
	}
	.parallax_diagonal{
		position: absolute;
		@include transform(translate(0%, 0));
		width: 300px;
		height: 518px;
		&[data-align="left"]{
			left: 0px;
		}
		&[data-align="right"]{
			right: 0px;
		}
		&[data-diagonal="1"]{
			background: url("images/divers/diagonales1.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
		&[data-diagonal="2"]{
			background: url("images/divers/diagonales2.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
		&[data-diagonal="3"]{
			background: url("images/divers/diagonales3.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
		&[data-diagonal="4"]{
			background: url("images/divers/diagonales4.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
		&[data-diagonal="5"]{
			background: url("images/divers/diagonales5.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
	}
	.parallax_cross{
		position: absolute;
		left: 50%;
		@include transform(translate(-50%, 0));
		width: 30px;
		height: 30px;
		&[data-cross="big-blue"]{
			background: url("images/divers/croix-grande-bleue.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
		&[data-cross="mid-gold"]{
			background: url("images/divers/croix-moyenne-doree.svg") no-repeat 0 0;
			background-size: 100% 100%;	
		}
		&[data-cross="big-gold"]{
			background: url("images/divers/croix-grande-doree.svg") no-repeat 0 0;
			background-size: 100% 100%;	
		}
		&[data-cross="small-blue"]{
			background: url("images/divers/croix-petite-bleue.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
		&[data-cross="small-gold"]{
			background: url("images/divers/croix-petite-doree.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
	}
	.parallax_grid{
		position: absolute;
		left: 50%;
		@include transform(translate(-50%, 0));
		width: 300px;
		height: 50px;
		opacity: .3;
		&[data-grid="grid-white"]{
			background: url("images/divers/grille-blanche.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
		&[data-grid="grid-blue"]{
			background: url("images/divers/grille-bleue.svg") no-repeat 0 0;
			background-size: 100% 100%;
		}
	}

	.silhouette_dribble{
		background: url("images/divers/silhouette-adversaire-dribbles.svg") no-repeat 0 0;
		background-size: 100% 100%;
		width: 400px;
		height: 400px;
		position: absolute;
		left: 50%;
		@include transform(translate(-100%, 0));
		margin-left: -200px;
		top: 1600px;
		opacity: 0;
		@include transition((all), 0.6s, ease-in-out);
		&.displayed{
			opacity: 1;
			@include transform(translate(-50%, 0));
		}
	}

	.silhouette_dribble_joueur{
		position: absolute;
		right: 50%;
		@include transform(translate(150%, 0));
		margin-right: 0px;
		top: 1600px;
		opacity: 0;
		width: 400px;
		@include transition((all), 1s, ease-in-out);
		&.displayed{
			opacity: 1;
			@include transform(translate(90%, 0));
		}
		img{
			width: 50%;
		}
		&[data-joueur="ocampos"]{
			width: 600px;
			margin-right: 100px;
		}
		&[data-joueur="payet"]{
			width: 450px;
		}
		&[data-joueur="sanson"]{
			width: 420px;
		}
		&[data-joueur="thauvin"]{
			width: 400px;
		}
		&[data-joueur="germain"]{
			width: 360px;
		}
		&[data-joueur="balotelli"]{
			width: 480px;
		}
		&[data-joueur="lopez"]{
			width: 650px;
			margin-right: 80px;
		}
		&[data-joueur="amavi"]{
			width: 600px;
			margin-right: 40px;
		}
		&[data-joueur="radonjic"]{
			width: 550px;
		}
		&[data-joueur="njie"]{
			width: 780px;
			margin-right: 100px;
		}
		&[data-joueur="sarr"]{
			width: 650px;
			margin-right: 100px;
		}
	}

	.degrade_dribble{
		position: absolute;
		top: 1990px;
		left: 50%;
		@include transform(translate(-50%, 0));
		background: url("images/divers/ligne-degrade-joueur-stats.svg") no-repeat 0 0;
		background-size: 100% 100%;
		width: 320px;
		height: 14px;
	}

	.silhouette_keeper{
		width: 450px;
		position: absolute;
		left: 50%;
		@include transform(translate(-100%, 0));
		margin-left: -230px;
		top: 2130px;
		opacity: 0;
		@include transition((all), 1s, ease-in-out);
		&.displayed{
			opacity: 1;
			@include transform(translate(-0%, 0));
		}
		img{
			width: 100%;
		}
		&[data-joueur="pele"]{
			width: 350px;
		}
	}

	.degrade_keeper{
		position: absolute;
		top: 2540px;
		left: 50%;
		@include transform(translate(-50%, 0));
		background: url("images/divers/ligne-degrade-joueur-stats.svg") no-repeat 0 0;
		background-size: 100% 100%;
		width: 320px;
		height: 14px;
	}
		
}

#Parallax1{
	.parallax_elem{
		&[data-elem="0"]{
			top: 340px;
			margin-left: -300px;
		}
		&[data-elem="1"]{
			top: 200px;
		}
		&[data-elem="2"]{
			top: 200px;
		}
		&[data-elem="3"]{
			top: 600px;
			margin-left: 40px;
		}
		&[data-elem="4"]{
			top: 750px;
			margin-left: -150px;
		}
		&[data-elem="5"]{
			top: 800px;
			margin-left: -40px;
		}
		&[data-elem="6"]{
			top: 900px;
			margin-left: 100px;
		}
		&[data-elem="7"]{
			top: 950px;
			margin-left: -130px;
		}
		&[data-elem="8"]{
			top: 200px;
			margin-left: 100px;
		}
		&[data-elem="9"]{
			top: 860px;
		}
		&[data-elem="10"]{
			top: 900px;
		}
		&[data-elem="11"]{
			top: 1670px;
			margin-left: -150px;
		}
		&[data-elem="12"]{
			top: 1610px;
			margin-left: -50px;
		}
		&[data-elem="13"]{
			top: 1570px;
			margin-left: 100px;
		}
		&[data-elem="14"]{
			top: 1630px;
			margin-left: 180px;
		}
		&[data-elem="15"]{
			top: 1650px;
		}
		&[data-elem="16"]{
			top: 2150px;
			margin-left: -100px;
		}
		&[data-elem="17"]{
			top: 2200px;
			margin-left: 100px;
		}
		&[data-elem="18"]{
			top: 2200px;
			margin-left: -200px;
		}
		&[data-elem="19"]{
			top: 2300px;
		}
		&[data-elem="20"]{
			top: 2750px;
		}
		&[data-elem="21"]{
			top: 3000px;
		}
		&[data-elem="22"]{
			top: 2800px;
		}
		&[data-elem="23"]{
			top: 3300px;
			margin-left: -150px;
		}
		&[data-elem="24"]{
			top: 3780px;
			margin-left: 150px;
		}
		&[data-elem="25"]{
			top: 3820px;
			margin-left: -150px;
		}
		&[data-elem="26"]{
			top: 3770px;
		}
		&[data-elem="27"]{
			top: 4200px;
			margin-left: 200px;
		}
		&[data-elem="28"]{
			top: 4400px;
		}
		&[data-elem="29"]{
			top: 4500px;
			margin-left: 120px;
		}
		&[data-elem="30"]{
			top: 4620px;
			margin-left: 180px;
		}
		&[data-elem="31"]{
			top: 4880px;
			margin-left: 160px;
		}
		&[data-elem="32"]{
			top: 5070px;
			margin-left: -100px;
		}
		&[data-elem="33"]{
			top: 5170px;
			margin-left: -80px;
		}
		&[data-elem="34"]{
			top: 5000px;
			margin-left: 10px;
		}
		&[data-elem="35"]{
			top: 5000px;
		}
		&[data-elem="36"]{
			top: 5200px;
			margin-left: -200px;
		}
		&[data-elem="37"]{
			top: 5600px;
			margin-left: 200px;
		}
		&[data-elem="38"]{
			top: 5500px;
		}
		&[data-elem="39"]{
			top: 5780px;
			margin-left: 80px;
		}


		&[data-elem="40"]{
			top: 6300px;
			margin-left: -150px;
		}
		&[data-elem="41"]{
			top: 6780px;
			margin-left: 150px;
		}
		&[data-elem="42"]{
			top: 6820px;
			margin-left: -150px;
		}
		&[data-elem="43"]{
			top: 6770px;
		}
		&[data-elem="44"]{
			top: 7200px;
			margin-left: 200px;
		}
		&[data-elem="45"]{
			top: 7400px;
		}
		&[data-elem="46"]{
			top: 7500px;
			margin-left: 120px;
		}
		&[data-elem="47"]{
			top: 7620px;
			margin-left: 180px;
		}
		&[data-elem="48"]{
			top: 7880px;
			margin-left: 160px;
		}
		&[data-elem="49"]{
			top: 8070px;
			margin-left: -100px;
		}
		&[data-elem="50"]{
			top: 8170px;
			margin-left: -80px;
		}
		&[data-elem="51"]{
			top: 8000px;
			margin-left: 10px;
		}
		&[data-elem="52"]{
			top: 8000px;
		}
		&[data-elem="53"]{
			top: 8200px;
			margin-left: -200px;
		}
		&[data-elem="54"]{
			top: 8600px;
			margin-left: 200px;
		}
		&[data-elem="55"]{
			top: 8500px;
		}
		&[data-elem="56"]{
			top: 8780px;
			margin-left: 80px;
		}
	}
}