@import 'mixins/global';

#AppContent .content_block[data-block="quiz"]{
	width: 320px;
	height: 500px;
	position: relative;
	left: 50%;
	@include transform(translate(-50%, 0));
	padding:10px 20px 40px;
	margin-top: 100px;
	.quiz_diago{
		position: absolute;
		z-index: 20;
		width: 300px;
		height: 519px;
		pointer-events:none;
		&[data-diago="1"]{
			background: url("images/divers/diagonales-quizz-G.svg") no-repeat 0 0;
			background-size: 100% 100%;
			left: -140px;
			top: -220px;
		}
		&[data-diago="2"]{
			background: url("images/divers/diagonales-quizz-D.svg") no-repeat 0 0;
			background-size: 100% 100%;
			right: -90px;
			top: -50px;
		}
		&[data-diago="3"]{
			background: url("images/divers/diagonales-quizz-G.svg") no-repeat 0 0;
			background-size: 100% 100%;
			left: -140px;
			top: 220px;
		}
	}
	.bottomr_triangle{
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 0 50px 50px;
		border-color: transparent transparent $colorCyan transparent;
		position: absolute;	
		right: -3px;
		bottom: -3px;
		z-index: 10;
	}
	.quiz_frontbg{
		background: #fff;
		position: absolute;
		left: 0px;
		right: 10px;
		top: 0px;
		bottom: 10px;
		z-index: 1;
		overflow: hidden;
		&:before{
			content:"";
			position: absolute;
			left: 0px;
			top: 0px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 40px 40px 0 0;
			border-color: $colorCyan transparent transparent transparent;
		}
		&:after{
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 40px 40px;
			border-color: transparent transparent $colorGold transparent;
			position: absolute;	
			right: 0px;
			bottom: 0px;
		}
	}
	.quiz_bg{
		background: $colorGold;
		position: absolute;
		left: 10px;
		top: 10px;
		right: 0px;
		bottom: 0px;
		z-index: -1;
	}
	.quiz_wrapper{
		position: absolute;
		left: 0px;
		top: 0px;
		width: 400%;
		height: 100%;
		@include transform(translate(0, 0));
		@include transition((all), 0.6s, ease-in-out);
	}
	.quiz_slide{
		position: relative;
		float: left;
		width: 25%;
		height: 100%;
		padding: 0 20px;
		.slide_title{
			color:$colorGold;
			font-size: 70px;
			line-height: 70px;
			font-family: "staatlichesregular";
			.back_text{
				position: absolute;
				left: 50%;
				@include transform(translate(-50%, 0));
				top: 22px;
				margin-left: 2px;
				color:#fff;
				text-shadow:1px 1px $colorGold;
			}
			.front_text{
				position: relative;
			}
		}
		&[data-slide="question_1"], &[data-slide="question_2"], &[data-slide="question_3"]{
			padding: 0px;
			.slide_title{
				font-size: 40px;
				position: relative;
				margin: 0px 0;
				.back_text{
					top: 2px;
				}
				.title_nb{
					position: absolute;
					left: 50%;
					@include transform(translate(-50%, 0));
					margin-left: 76px;
					top: 38px;
					color: $colorCyan;
					font-size: 55px;
					line-height: 55px;
					z-index: 2;
					&.back{
						z-index: 1;
						color: #fff;
						text-shadow:1px 1px $colorCyan;
						top: 40px;
						margin-left: 78px;
					}
					.nb_figure{
						font-size: 78px;
						position: relative;
						top: -4px;
						font-family: "shkoder_1989black";
					}
				}
			}
			.slide_subtitle{
				margin-top: 0px;
				padding: 0 20px;
			}
			.slide_answers{
				margin-top: 20px;
				.answer_bt{
					height: 75px;
					line-height: 75px;
					width: 100%;
					position: relative;
					text-align: left;
					margin-top: 10px;
					cursor: pointer;
					&:after{
						content:"";
						position: absolute;
						left: 0px;
						bottom: 0px;
						height: 6px;
						width: 100%;
						/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0e96d2+0,ffffff+100 */
						background: rgb(14,150,210); /* Old browsers */
						background: -moz-linear-gradient(left, rgba(14,150,210,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(left, rgba(14,150,210,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to right, rgba(14,150,210,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0e96d2', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
					}
					.answer_playerpic{
						width: 75px;
						height: 75px;
						display: inline-block;
						vertical-align: top;
					}
					.answer_playername{
						color: $colorGold;
						font-size: 24px;
						text-align: left;
						display: inline-block;
						margin-left: 30px;
						vertical-align: top;
					}
				}
			}
		}
		.slide_subtitle{
			font-size: 13px;
			line-height: 18px;
			font-family: "montserratregular";
			color:$colorGold;
		}
		.slide_instructions{
			font-size: 24px;
			line-height: 28px;
			font-family: "staatlichesregular";
			color:$colorGold;
			margin-top: 30px;
		}
		.slide_readytext{
			color: $colorBlueDark;
			font-size: 40px;
			font-family: "staatlichesregular";
			line-height: 40px;
			margin-top: 30px;
		}
		.slide_startbt{
			color: #fff;
			border:0px solid;
			font-size: 24px;
			line-height: 28px;
			margin-top: 20px;
			position: relative;
			max-width: 200px;
			cursor: pointer;
			display: inline-block;
			&:before{
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				@include transform(translate(-50%, -50%));
				background: #005173;
				width: 90%;
				height: 110%;
			}
			.startbt_text{
				background: $colorCyan;
				position: relative;
				z-index: 10;
				padding: 10px 30px;
			}
			&:hover{
				.startbt_text{
					background: $colorGold;
				}
			}
		}
		.slide_step{
			position: absolute;
			left: 0px;
			top: 110px;
			width: 100%;
			opacity: 0;
			z-index: -1;
			@include transition((opacity), 0.6s, ease-in-out);
			.step_toggle{
				display: none;
				margin-top: 20px;
				.toggle_text{
					font-size: 60px;
					line-height: 60px;
					font-family: "staatlichesregular";
					color: $colorGold;
					.back{
						position: absolute;
						z-index: 1;
						left: 50%;
						@include transform(translate(-50%, 0));
						text-shadow: 1px 1px $colorGold;
						color: #fff;
						top: 22px;
						margin-left: 2px;
						width: 100%;
					}
					.front{
						position: relative;
						z-index: 2;
					}
				}
				.block_gif{
					opacity: 1;
					width: 90%;
					position: relative;
					left:5%;
					@include transition((all), 0.6s, ease-in-out);
				}
			}
			.toggle_player{
				position: absolute;
				top: 100px;
				left: 0px;
				width: 100%;
				text-align: center;
				opacity: 0;
				z-index: -1;
				@include transition((all), 0.6s, ease-in-out);
				.player_title{
					font-size: 28px;
					color: $colorGold;
					font-family: "staatlichesregular";
				}
				&[data-slide="question_3"]{
					margin-top: -94px;
					background: #fff;
					.player_card{
						margin-top: 30px;
						&:after{
							bottom: 22px;
							left: 64px;
						}
						.answer_playerpic{
							position: relative;
							left: 60px;
							top: -22px;
						}
						.answer_playername{
							left: 30px;
							position: relative;
							top: -20px;
						}
					}
					.player_title{
						margin-bottom: 8px;
					}
					.player_text{
						margin-top: -10px;
						padding: 0 50px;
					}
					.q3_top5{
						color: $colorGold;
						margin-top: 10px;
						.top5_player{
							display: block;
							text-align: left;
							height: 34px;
							margin: 0 30px;
							line-height: 34px;
							position: relative;
							.player_diamond{
								color: #fff;
								width: 35px;
								height: 35px;
								font-size: 20px;
								line-height: 35px;
								text-align: center;
								position: absolute;
								left: 0px;
								top: 50%;
								@include transform(translate(0%, -50%));
								&:before{
									content:"";
									position: absolute;
									width: 100%;
									height: 100%;
									background: $colorGold;
									@include transform(translate(-50%, -50%) rotate(45deg));
									left: 50%;
									top: 50%;
									z-index: -1;
								}
							}
							.player_name{
								margin-left: 60px;
								font-size: 22px;
							}
						}
					}
				}
				.player_card{
					width: 100%;
					height: 75px;
					text-align: left;
					position: relative;
					line-height: 75px;
					&:after{
						content:"";
						position: absolute;
						left: 0px;
						bottom: 0px;
						height: 6px;
						width: 100%;
						/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0e96d2+0,ffffff+100 */
						background: rgb(14,150,210); /* Old browsers */
						background: -moz-linear-gradient(left, rgba(14,150,210,1) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
						background: -webkit-linear-gradient(left, rgba(14,150,210,1) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
						background: linear-gradient(to right, rgba(14,150,210,1) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0e96d2', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
					}
					.answer_playerpic{
						width: 75px;
						height: 75px;
						display: inline-block;
						vertical-align: top;
					}
					.answer_playername{
						color: $colorGold;
						font-size: 24px;
						text-align: left;
						display: inline-block;
						margin-left: 30px;
						vertical-align: top;
					}
					.answer_playerdiamond{
						color: #fff;
						width: 50px;
						height: 50px;
						font-size: 36px;
						line-height: 50px;
						text-align: center;
						position: absolute;
						left: 10px;
						z-index: 100;
						bottom: 0px;
						@include transform(translate(0%, 0%));
						&:before{
							content:"";
							position: absolute;
							width: 100%;
							height: 100%;
							background: $colorGold;
							@include transform(translate(-50%, -50%) rotate(45deg));
							left: 50%;
							top: 50%;
							z-index: -1;
						}
					}
				}
				.player_text{
					color: $colorGold;
					font-size:13px;
					line-height: 18px;
					font-family: "montserratregular";
					margin-top: 30px;
					padding: 0 20px;
				}
				.step_nextbt{
					font-size: 24px;
					font-family: "staatlichesregular";
					color: $colorGold;
					border: 0px solid;
					line-height: 30px;
					margin-top: 10px;
					position: relative;
					position: relative;
					left: -10px;
					outline: 0px;
					background:none;
					cursor: pointer;
					&:after{
						content: "";
						position: absolute;
						left: 100%;
						margin-left: 0px;
						top: 50%;
						@include transform(translate(0, -50%) rotate(-90deg));
						background: url("images/divers/fleche.svg") no-repeat 0 0;
						background-size: 100% 100%;
						width: 50px;
						height: 30px;
					}
					&:focus{
						border:0px solid;
					}
					&[data-question="question_3"]{
						&:after{
							top: 100%;
							left: 50%;
							@include transform(translate(-50%, 0%) rotate(0deg));
						}
					}
				}
			}
			&.aftergif{
				.step_toggle .block_gif{
					opacity: 0;
					z-index: -1;
				}
				.toggle_player{
					opacity: 1;
					z-index: 100;
				}
			}
		}
		&[data-step="1"]{
			.slide_step[data-step="1"]{
				opacity: 1;
				z-index: 1000;
			}
		}
		&[data-step="2"]{
			.slide_step[data-step="2"]{
				opacity: 1;
				z-index: 1000;
			}
		}
		&[data-result="win"]{
			.step_toggle[data-toggle="win"]{
				display: inline-block !important;
			}
		}
		&[data-result="lose"]{
			.step_toggle[data-toggle="lose"]{
				display: inline-block !important;
			}
		}
	}
}