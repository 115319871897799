/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 9, 2019 */
@font-face {
    font-family: 'montserratitalic';
    src: url('fonts/montserrat-italic.woff2') format('woff2'),
         url('fonts/montserrat-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat_lightregular';
    src: url('fonts/montserrat-light.woff2') format('woff2'),
         url('fonts/montserrat-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratregular';
    src: url('fonts/montserrat-regular.woff2') format('woff2'),
         url('fonts/montserrat-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat_semiboldregular';
    src: url('fonts/montserrat-semibold.woff2') format('woff2'),
         url('fonts/montserrat-semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'montserrat_blackregular';
    src: url('fonts/montserrat-black-webfont.woff2') format('woff2'),
         url('fonts/montserrat-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'staatlichesregular';
    src: url('fonts/staatliches-regular.woff2') format('woff2'),
         url('fonts/staatliches-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'shkoder_1989black';
    src: url('fonts/shkoder1989-black-webfont.woff2') format('woff2'),
         url('fonts/shkoder1989-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'shkoder_1989light';
    src: url('fonts/shkoder_1989_light.woff2') format('woff2'),
         url('fonts/shkoder_1989_light.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}