@import 'mixins/global';

body[data-page="splash"]{
	background: #fff;

	#AppContent{
		width: 100%;
		max-width: none;
		.content_block{
			width: 100%;
			&[data-block="splash"]{
				background: $colorCyan;
				margin-top: 60px;
				padding: 0px 0px 120px;
				.bigger_p{
					line-height: 36px;
				}
				.splash_disclaimer{
					font-size: 11px;
					line-height: 14px;
					max-width: 300px;
					margin-top: 40px;
					position: relative;
					font-family: "montserratregular";
					left: 50%;
					@include transform(translate(-50%, 0));
				}
				.triangle{
					position: absolute;
					right:0px;
					bottom:100%;
					width: 0;
					height: 0;
					border-style: solid;
					border-color: transparent transparent $colorCyan transparent;
				}
				.triangle_1{
					border-width: 0 0 100px 650px;
				}
				.triangle_2{
					border-width: 0 0 120px 650px;
					opacity: .7;
				}
				.splash_input{
					height: 30px;
					width: 270px;
					border: 0px;
					color: $colorCyan;
					font-size: 20px;
					padding: 0 10px;
					font-family: "montserratregular";
				}
				.start_bt{
					font-family: "staatlichesregular";
					font-size: 30px;
					line-height: 34px;
					position: relative;
					display: inline-block;
					vertical-align: top;
					margin-top: 40px;
					color: #fff;
					text-decoration: none;
					cursor: pointer;
					background: none;
					border: 0px;
					width: 200px;
					opacity: 0.4;
					&.active{
						opacity: 1;
					}
					.bt_label{
						background: $colorGold;
						position: relative;
						padding: 4px 10px;
					}
					&:before{
						content: "";
						position: absolute;
						left: 50%;
						top: 50%;
						@include transform(translate(-50%, -50%));
						background: #005173;
						width: 90%;
						height: 115%;
					}
					&:hover{
						.bt_label{
							background: #fff;
							color: $colorGold;
						}
					}
				}
			}
		}
	}
}

#SplashOpacity{
	background: rgba(0,0,0,0.6);
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 300;
	display: none;
	opacity: 0;
	@include transition((all), 0.3s, ease-in-out);
}

#Splash{
	width: 100%;
	position: fixed;
	left: 0px;
	bottom: 0px;
	height: 180px;
	z-index: 1000;
	background: $colorBlueDark;
	font-family: "montserratregular";
	color: #fff;
	padding: 14px 20px 20px;
	display: none;
	@include transform(translate(0, 200%));
	@include transition((all), 0.6s, ease-in-out);
	.content_block{
		width: 100%;
		text-align: center;
		&[data-block="splash"]{
			.splash_disclaimer{
				font-size: 14px;
				line-height: 20px;
				max-width: 600px;
				width: 100%;
				position: relative;
				left: 50%;
				@include transform(translate(-50%, 0));
				font-family: "montserratregular";
			}
			.triangle{
				position: absolute;
				right:0px;
				bottom:100%;
				width: 0;
				height: 0;
				border-style: solid;
				border-color: transparent transparent $colorBlueDark transparent;
			}
			.triangle_1{
				border-width: 0 0 80px 650px;
			}
			.triangle_2{
				border-width: 0 0 100px 650px;
				opacity: .7;
			}
		}
		.splash_bt{
			font-family: "staatlichesregular";
			font-size: 30px;
			line-height: 34px;
			position: relative;
			display: inline-block;
			vertical-align: top;
			margin-top: 10px;
			color: #fff;
			text-decoration: none;
			cursor: pointer;
			background: none;
			border: 0px;
			width: 100px;
			opacity: 1;
			outline: 0px;
			border: 0px;
			.bt_label{
				background: $colorGold;
				position: relative;
				padding: 4px 10px;
				width: 100%;
				display: inline-block;
			}
			&:before{
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				@include transform(translate(-50%, -50%));
				background: #005173;
				width: 90%;
				height: 115%;
			}
			&:hover{
				.bt_label{
					background: #fff;
					color: $colorGold;
				}
			}
		}
	}
}