@import 'mixins/global';

.modalINHA{
	width: 512px;
	height: 640px;
	padding-top: 20px;
	padding-bottom: 20px;
	padding: 20px;
	background-color: white;
	position:absolute;
	top:200%;
	left:50%;
	@include transform(translate(-50%, -50%));
	opacity: 0;
	-webkit-box-shadow: 0px 0px 70px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 70px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 70px 0px rgba(0,0,0,0.75);
	pointer-events: none;
	-moz-transition: top 0.6s ease-in-out, opacity 0.3s ease-in-out; 
	-o-transition: top 0.6s ease-in-out, opacity 0.3s ease-in-out; 
	-ms-transition: top 0.6s ease-in-out, opacity 0.3s ease-in-out; 
	-webkit-transition: top 0.6s ease-in-out, opacity 0.3s ease-in-out; 
    transition: top 0.6s ease-in-out, opacity 0.3s ease-in-out; 
    z-index: 1000;
    &.show{
    	opacity: 1;
		pointer-events: all;
		top:50%;
    }

    .modal_closebt{
    	width: 22px;
		height: 45px;
		background-color: $colorRed;
		position: absolute;
		top:0;
		left:50%;
		margin-left: -11px;
		cursor: pointer;
		&:after{
			content:url("./img/close-cross.svg");
			width: 60%;
			position: absolute;
			left:50%;
			@include transform(translate(-50%, 5px));
			height: auto;
			display: block;
		}
    }
}